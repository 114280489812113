.section-footer {
    .navigation {
        a {
            color: #ffffff;
            &:hover {
                color: var(--tc-theme-primary-1);
            }
        }
    }
}
